/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2024-05-27 17:54:58
 */
import { request } from "@/request/request";
import { parameterChange } from "@/utils/utils";

export default {
  // 学生端登录
  login(data) {
    return request({
      url: "/auth/login",
      method: "post",
      data,
    });
  },

  // 学生确认登录
  confirmLogin(data) {
    return request({
      url: "/auth/confirmLogin?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取登录验证码
  uuid(data) {
    return request({
      url: "/auth/uuid",
      method: "get",
    });
  },

  // 发送手机验证码
  sendSms(data) {
    return request({
      url: "/auth/sendSms",
      method: "post",
      data,
    });
  },

  // 修改密码
  updatePassWord(data) {
    return request({
      url: "/auth/updatePassWord",
      method: "post",
      data,
    });
  },
};
