/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2024-05-29 18:19:51
 */
import router from "@/router";
import api from "@/api";
import { defineStore } from "pinia";
import { message, Modal } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

const useUserStore = defineStore("user", {
  state: () => ({
    // 登录标识
    token: "",
    // 用户姓名
    name: "",
    // 用户手机号
    phone: "",
    // 用户状态-1、0：赛前测试；1：正式比赛；2：待出成绩；3、4、5：成绩；6：预选赛成绩
    state: -1,
    // 比赛id
    raceId: 0,
    // 学生id
    stuId: 0,
  }),

  getters: {
    userInfo(state) {
      return { ...state };
    },
  },
  actions: {
    SET_TOKEN(token) {
      this.token = token;
      sessionStorage.setItem("token", token);
    },
    SET_NAME(name) {
      this.name = name;
    },
    SET_PHONE(phone) {
      this.phone = phone;
    },
    SET_STATE(state) {
      this.state = state;
    },
    SET_RACEID(raceId) {
      this.raceId = raceId;
    },
    SET_STUID(stuId) {
      this.stuId = stuId;
    },
    SET_INFO(info) {
      const { name, phone, raceId, stuId } = info;
      this.name = name;
      this.phone = phone;
      this.raceId = raceId;
      this.stuId = stuId;
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("phone", phone);
      sessionStorage.setItem("raceId", raceId);
      sessionStorage.setItem("stuId", stuId);
    },
    logout() {
      this.token = "";
      this.name = "";
      this.phone = "";
      this.state = -1;

      sessionStorage.removeItem("token");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("comMess");
      sessionStorage.removeItem("raceId");
      sessionStorage.removeItem("stuId");
    },
  },
});

export default useUserStore;
