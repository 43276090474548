/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-04-27 21:53:28
 */
// import { md5 } from 'md5js';
import dayjs from "dayjs";
import * as api from "@/api";
import * as qiniu from "qiniu-js";
import { useUserStore } from "@/store/index";
import { message } from "ant-design-vue";

/**
 * @description: 七牛云上传
 */
export function qnUpload(fileEntry, parentPath) {
  const { VUE_APP_CDN, NODE_ENV } = process.env,
    { v4: UUID } = require("uuid");

  return new Promise(async (resolve, reject) => {
    const { file } = fileEntry,
      // 生成一个符合标准的 UUID
      uuid = UUID(),
      time = new dayjs().format("YYMMDD-HHmm"),
      suffix = file.name.split(".").pop(),
      fileName = `${uuid}/${file.name}`,
      path = `${parentPath}/${fileName}`,
      fullPath = NODE_ENV == "development" ? `test/${path}` : path;
    console.log(fullPath);

    if (!["mp4", "jpg", "png"].includes(suffix.toLowerCase()))
      return reject({ msg: "文件格式不符！", data: suffix });

    const onUpload = async () => {
      return new Promise((resolve) => {
        api.default.getToken().then((res) => {
          if (res) {
            const observable = qiniu.upload(file, fullPath, res.data);
            observable.subscribe(
              () => {},
              (err) => {
                resolve({ success: false, data: err });
              },
              (res) => {
                resolve({ success: true, data: res });
              }
            );
          }
        });
      });
    };

    const res = await onUpload();

    if (res) {
      let fileUrl = `${VUE_APP_CDN}/${res.data.key}`,
        temp = {
          uid: uuid,
          url: fileUrl,
          status: "done",
          name: file.name,
          fileName,
        };

      if (["mp4"].includes(suffix.toLowerCase())) {
        const audioUrl = URL.createObjectURL(file),
          audioElement = new Audio(audioUrl);

        audioElement.onloadedmetadata = async () => {
          const videoTime = parseInt(audioElement.duration),
            videoSize = (file.size / 1024).toFixed(2);

          temp = { ...temp, videoTime, videoSize };
          resolve(temp);
        };
      }
      if (["jpg", "png"].includes(suffix.toLowerCase())) {
        resolve(temp);
      }
    }
  });
}

/**
 * @description: 文件下载方式1
 * @param {*} url
 * @return {*}
 */
export function downLoadFile(url) {
  if (!url) {
    // message.error('无下载链接');
    return;
  }
  const a = document.createElement("a");
  a.href = `${process.env.VUE_APP_API_URL}/api/other/downLoadFile?file_url=${url}`;
  document.body.appendChild(a);
  a.click();
  return;
}

/**
 * @description: 文件下载方式2
 * @param {*} url
 * @param {*} name
 * @param {*} method
 * @return {*}
 */
export function download(url, name, method = "get") {
  if (!url) {
    message.error("无下载链接");
    return;
  }

  const xhr = new XMLHttpRequest();
  xhr.open(method, url.split(":")[1], true);
  xhr.responseType = "blob";
  xhr.onload = (e) => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(xhr.response);
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  xhr.send();
}

/**
 * @description: 文件上传
 * @param {*} api
 * @return {*}
 */
export function uploadFile(url, formData, method = "post") {
  const counter = useUserStore();
  const unix = dayjs().unix();
  const sign = md5(btoa(unix + "nitouche"), 32);

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);

    xhr.setRequestHeader("iv", unix);
    xhr.setRequestHeader("sign", sign);
    xhr.setRequestHeader("token", counter.token);
    xhr.setRequestHeader("Cache-Control", "no-cache");

    xhr.send(formData);
    xhr.onload = async function () {
      const res = JSON.parse(xhr.response);
      if (res.code === 200) resolve(res);
      else {
        reject(res);
        // message.warning(res.msg, 2);
      }
    };
  });
}
