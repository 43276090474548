/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-05-08 18:19:41
 */
import {
  Button,
  Input,
  Result,
  Avatar,
  Radio,
  Checkbox,
  Modal,
  Row,
  Col,
  Tabs,
  Statistic,
  Select,
  Image,
  Spin,
  Upload,
} from "ant-design-vue";
import { message } from 'ant-design-vue';

import 'ant-design-vue/dist/antd.css';

export function lazyUse(app) {
  app.use(Button);
  app.use(Input);
  app.use(Result);
  app.use(Avatar);
  app.use(Radio);
  app.use(Checkbox);
  app.use(Modal);
  app.use(Row);
  app.use(Col);
  app.use(Tabs);
  app.use(Statistic);
  app.use(Select);
  app.use(Image);
  app.use(Spin);
  app.use(Upload);

  app.config.globalProperties.$message = message;
  app.config.globalProperties.$modal = Modal;
}
