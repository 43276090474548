<!--
 * @Date: 2023-05-04 15:21:46
 * @LastEditTime: 2024-06-14 10:48:17
-->
<template>
  <div class="media_box" v-show="cameraOnline">
    <video ref="videoRef" muted autoplay></video>
    <canvas ref="canvas" width="400" height="300"></canvas>
  </div>
  <div class="media_bad_box" v-if="isLoaded && !cameraOnline">
    <div class="tips_box">
      <img
        class="icon"
        src="@/assets/examination/icon_about_red.png"
        alt="icon"
      />
      <p class="tips_text">{{ errorMap[message] ?? message }}</p>
    </div>
    <div
      class="process_box"
      v-if="
        ['permission denied', 'permission denied by system'].includes(message)
      "
    >
      <p class="process_text">请按照下图进行操作</p>
      <img
        src="@/assets/examination/process.png"
        alt="img"
        class="process_img"
        v-if="message == 'permission denied'"
      />
      <div
        class="private_box"
        v-else-if="message == 'permission denied by system'"
      >
        <img src="@/assets/examination/process2.png" alt="img" />
        <img src="@/assets/examination/process3.png" alt="img" />
        <img src="@/assets/examination/process4.png" alt="img" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';
import { useCommonStore, useUserStore } from '@/store/index';

const { proxy } = getCurrentInstance();
const userStore = useUserStore(),
  commonStore = useCommonStore();

let videoDom;

onMounted(() => {
  videoDom = proxy.$refs.videoRef;
  openCamera();
});

onBeforeUnmount(() => {
  commonStore.interval && commonStore.CLEAR_CAMERA_INTERVAL();
  closeCamera();
});

const isLoaded = ref(false),
  openCamera = () => {
    // 设置定时器拍照之前先判断是否有设置定时器成功
    interval && commonStore.SET_CAMERA_INTERVAL(interval);

    if (
      navigator?.mediaDevices?.getUserMedia ||
      navigator?.webkitGetUserMedia ||
      navigator?.mozGetUserMedia
    ) {
      // 先关闭旧的摄像头流，再打开新的流
      closeCamera();
      // 相机配置为声音和画面
      const constraints = { 
        video: true
      // , audio: true
       };

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((res) => {
            success(res);
          })
          .catch((err) => {
            error(err);
          })
          .finally(() => {
            isLoaded.value = true;
          });
      } else if (navigator.webkitGetUserMedia) {
        console.log(2);
        navigator
          .webkitGetUserMedia(constraints)
          .then((res) => {
            success(res);
          })
          .catch((err) => {
            error(err);
          })
          .finally(() => {
            isLoaded.value = true;
          });
      } else if (navigator.mozGetUserMedia) {
        console.log(3);
        navigator
          .mozGetUserMedia(constraints)
          .then((res) => {
            success(res);
          })
          .catch((err) => {
            error(err);
          })
          .finally(() => {
            isLoaded.value = true;
          });
      }
    } else {
      isLoaded.value = true;
      // message.value = '当前设备不支持摄像头/麦克风';
      message.value = '当前设备不支持摄像头';
    }
  };

const cameraOnline = ref(false);
watch(cameraOnline, (data) => {
  if (!data) openCamera();
  else if (commonStore.cameraInterval) commonStore.CLEAR_CAMERA_INTERVAL();

  emits('cameraChange', data);
});

const emits = defineEmits(['cameraChange']);
const success = (stream) => {
  videoDom.srcObject = stream;
  setTimeout(() => {
    videoDom.play();
  }, 200);
  cameraOnline.value = true;

  updateState();

  if (stream.oninactive == null) {
    // 监听流中断，流中断后将重新进行调用自身进行状态监测
    stream.oninactive = (e) => {
      cameraOnline.value = false;
    };
  }
};

const updateState = () => {
  const params = {
    logId: commonStore.logId,
    stage: userStore.state,
    devicePermission: 1,
  };
  proxy.$api.updateLog(params);
};

let interval = '';
// const errorMap = {
//     'permission dismissed': '请授权摄像头/麦克风相关的权限，否则无法答题',
//     'permission denied': '请开启摄像头/麦克风相关的权限，否则无法答题',
//     'permission denied by system':
//       '请前往"隐私-应用权限"开启摄像头/麦克风相关的权限',
//     'requested device not found': '未检测到摄像头/麦克风,请配备相关设备',
//     'could not start video source':
//       '无法访问到摄像头/麦克风，请检查设备是否被其他应用占用',
//   },
const errorMap = {
    'permission dismissed': '请授权摄像头相关的权限，否则无法答题',
    'permission denied': '请开启摄像头相关的权限，否则无法答题',
    'permission denied by system':
      '请前往"隐私-应用权限"开启摄像头相关的权限',
    'requested device not found': '未检测到摄像头,请配备相关设备',
    'could not start video source':
      '无法访问到摄像头，请检查设备是否被其他应用占用',
  },
  message = ref(''),
  error = (err) => {
    message.value = (err.message || err).toLowerCase();

    // 先清除旧的定时器再生成新的定时器
    commonStore.cameraInterval && commonStore.CLEAR_CAMERA_INTERVAL();

    interval = setInterval(() => {
      openCamera();
    }, 1000);
    interval && commonStore.SET_CAMERA_INTERVAL(interval);
    cameraOnline.value = false;
  };

const closeCamera = () => {
  if (!videoDom.srcObject) return;
  let stream = videoDom.srcObject;
  let tracks = stream.getTracks();
  tracks.forEach((track) => {
    track.stop();
  });
  videoDom.srcObject = null;
};

// 截图
const screenshot = () => {
  // 获取canvas
  if (!proxy.$refs.canvas) return;

  const canvas = proxy.$refs.canvas,
    ctx = canvas.getContext('2d');

  // 设置canvas 视图文件地址和大小
  ctx.drawImage(
    videoDom,
    0,
    0,
    videoDom.videoWidth,
    videoDom.videoHeight,
    0,
    0,
    canvas.width,
    canvas.height
  );
  // 将数据转为base64赋值给img标签的src属性
  const base64 = canvas.toDataURL('image/png');
  const file = base64.split('data:image/png;base64,')[1];
  proxy.$api.uploadImage({ file });
};
defineExpose({ openCamera, closeCamera, screenshot });
</script>

<style lang="less" scoped>
.media_box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 150px;
  border: 1px solid;

  video {
    width: 200px;
    height: 150px;
  }

  canvas {
    width: 400px;
    height: 300px;
    display: none;
  }
}

.media_bad_box {
  width: 100vw;
  height: calc(100vh - 96px);
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  position: fixed;
  left: 0;
  top: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .tips_box {
    width: fit-content;
    height: fit-content;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 96px;

    .icon {
      width: 25px;
      height: 25px;
    }

    .tips_text {
      line-height: 20px;
      margin-left: 5px;
      font-size: 19px;
      font-weight: 500;
    }
  }

  .process_box {
    width: fit-content;
    height: fit-content;
    margin-top: 20px;

    .process_text {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 19px;
      font-weight: 500;
    }

    .process_img {
      width: 900px;
      margin-top: 20px;
    }

    .private_box {
      width: 100%;
      height: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;

      img:not(:first-of-type) {
        margin-top: 5px;
      }
    }
  }
}
</style>
