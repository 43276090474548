<template>
  <div class="time">
    <div>{{ time }}</div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, watch } from "vue";

const props = defineProps({
  initialTime: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["finish"]);
const remainingTime = ref(props.initialTime);
const time = ref(msToTime(remainingTime.value));
let timerWorker = null;

const startWorker = (initialTime) => {
  if (window.Worker) {
    timerWorker = new Worker(
      new URL("../config/timerWorker.js", import.meta.url)
    );
    timerWorker.onmessage = (e) => {
      if (typeof e.data === "number") {
        remainingTime.value = e.data;
        time.value = msToTime(remainingTime.value);
        if (remainingTime.value === 0) {
          emit("finish");
        }
      }
    };

    timerWorker.postMessage(initialTime);
  } else {
    console.log("doesn't support Web Workers");
  }
};

watch(
  () => props.initialTime,
  (newInitialTime) => {
    if (newInitialTime > 0) {
      remainingTime.value = newInitialTime;
      time.value = msToTime(remainingTime.value);
      startWorker(newInitialTime);
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  if (timerWorker) {
    timerWorker.terminate();
  }
});

function msToTime(duration) {
  let seconds = Math.floor(duration % 60),
    minutes = Math.floor((duration / 60) % 60),
    hours = Math.floor((duration / (60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
}
</script>

<style  lang="less" scoped>
.time {
  color: #c9353f;
  font-weight: 600;
  font-family: unset;
  font-size: 1.25rem;
  line-height: 1.66667rem;
}
</style>
