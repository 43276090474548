import { request } from '@/request/request';
import { parameterChange } from '@/utils/utils';

export default {
  // 获取正式比赛试题
  getPaper(data) {
    return request({
      url: '/tbl-obj-info/getPaper',
      method: 'get',
      data,
    });
  },
  // 获取用户测试试题
  getPaperTest(data) {
    return request({
      url: '/tbl-obj-info/getPaperTest',
      method: 'get',
      data,
    });
  },
  // 评判学生考试成绩
  judgeAnswer(data) {
    return request({
      url: '/tbl-obj-answer/judgeAnswer',
      method: 'post',
      data,
    });
  },
  // 编译题交卷
  earlySubmit(data) {
    return request({
      url: '/tbl-score-record/earlySubmit',
      method: 'post',
      data,
    });
  },
  // 提交代码
  submitCompileCode(data) {
    return request({
      url: '/tbl-compile-info/submitCompileCode',
      method: 'post',
      data,
    });
  },
  // 测试比赛提交代码
  submitCompileTestCode(data) {
    return request({
      url: '/tbl-compile-info/submitCompileTestCode',
      method: 'post',
      data,
    });
  },

  // 运行代码
  runCompileCode(data) {
    return request({
      url: '/tbl-compile-info/runCompileCode',
      method: 'post',
      data,
    });
  },
  // 测试试卷编译题
  getCompileTestInfo(data) {
    return request({
      url: '/tbl-compile-info/getCompileTestInfo',
      method: 'get',
      data,
    });
  },
  // 正式试卷编译题
  getCompileInfo(data) {
    return request({
      url: '/tbl-compile-info/getCompileInfo',
      method: 'get',
      data,
    });
  },
  // 获取当前学生的比赛状态
  getStuRaceInfo(data) {
    return request({
      url: '/tbl-stu-info/getStuRaceInfo',
      method: 'get',
      data,
    });
  },
  // 保存编译题代码
  saveCompileCode(data) {
    return request({
      url: '/tbl-compile-info/saveCompileCode',
      method: 'post',
      data,
    });
  },
  // 获取编译题代码
  getCompileCode(data) {
    return request({
      url: '/tbl-compile-info/getCompileCode',
      method: 'post',
      data,
    });
  },
  // 赛前测试交卷
  testSubmit(data) {
    return request({
      url: '/tbl-score-record/testSubmit',
      method: 'post',
      data,
    });
  },
  // 更新比赛结束自动提交状态
  updateAutoSubmit(data) {
    return request({
      url: '/tbl-stu-info/updateAutoSubmit',
      method: 'post',
      data,
    });
  },
};
