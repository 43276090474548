<!--
 * @Author: gyh
 * @Date: 2024-05-23 16:59:20
 * @LastEditTime: 2024-05-30 16:04:51
-->
<template>
  <!-- <p class="title">{{ config.competition[VUE_APP_GAME] }}</p>
  <p class="title">{{ comMess.eventName }}</p> -->
  <div class="message_box">
    <p class="sub-title">
      <!-- 青创客展示组别、编程语言、赛区/上一级比赛赛区、赛期 -->
      <template v-if="VUE_APP_GAME == 'qck'">
        <!-- {{ comMess.groupName }} {{ comMess.langName }}
        {{ comMess.raceZonePast ?? comMess.raceZone }}
        {{ commonStore.periodMap[comMess.racePeriod] }} -->
        <i>{{ comMess.groupName }} </i><i>{{ comMess.langName }} </i
        ><i>{{ comMess.raceZonePast ?? comMess.raceZone }} </i>
        <i v-if="commonStore.RaceType === 0">
          {{ commonStore.periodMap[commonStore.comMess?.racePeriod] }}</i
        ><i v-else-if="[1, 2, 3].includes(commonStore.RaceType)">
          {{ raceTypeMap[commonStore.RaceType] }}
        </i>
      </template>
      <!-- 花都展示组别、编程语言 -->
      <template v-else-if="VUE_APP_GAME == 'hdycp'"
        >{{ comMess.groupName }} {{ comMess.langName }}</template
      >
      <!-- 山东展示编程语言 -->
      <template v-else>{{ comMess.langName }}</template>
    </p>
    <slot name="right"></slot>
  </div>
</template>

<script setup>
import { config } from "@/config";
import { useCommonStore } from "@/store";
const raceTypeMap = { 1: "预选赛", 2: "市赛", 3: "省赛" };

const commonStore = useCommonStore(),
  { VUE_APP_GAME } = process.env,
  props = defineProps({ comMess: Object }); // 比赛信息
</script>

<style lang="less" scoped>
.message_box {
  padding: 12px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #f9f6d0;
}
// .title {
//   height: 36px;
//   font-size: 28px;
//   font-weight: 600;
//   color: rgba(0, 0, 0, 0.9);
//   line-height: 36px;
//   text-align: center;

//   &:first-of-type {
//     margin-top: 48px;
//   }

//   &:nth-of-type(2) {
//     color: rgba(0, 0, 0, 0.6);
//     margin-top: 24px;
//   }
// }
.sub-title {
  height: 100%;
  flex: 1;
  font-family: SF Pro, SF Pro;
  font-weight: 590;
  font-size: 20px;
  color: #8b572a;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  box-sizing: border-box;
}
i {
  font-style: normal;
  margin-left: 5px;
}
</style>
