<template>
  <pre v-html="highlightedCode"></pre>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // 引入主题样式
import "prismjs/components/prism-c"; // 引入 C 语言语法高亮
import "prismjs/components/prism-python"; // 引入 Python 语法高亮

const props = defineProps({
  code: {
    type: String,
    required: true,
  },
  language: {
    type: String,
    required: true,
  },
});

const highlightedCode = ref("");

// 将 HTML 实体转换为纯文本
const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

// 保留换行效果，转换 <br /> 为换行符
const convertLineBreaks = (html) => {
  return html.replace(/<br\s*\/?>/gi, '\n'); // 将 <br /> 替换为换行符
};

const updateHighlightedCode = () => {
  const decodedCode = decodeHtml(props.code); // 解码
  const codeWithLineBreaks = convertLineBreaks(decodedCode); // 保留换行效果
  highlightedCode.value = Prism.highlight(codeWithLineBreaks, Prism.languages[props.language], props.language);
};

// 在组件挂载时初始化
onMounted(updateHighlightedCode);

// 监听 code 和 language 的变化
watch(() => props.code, updateHighlightedCode);
watch(() => props.language, updateHighlightedCode);
</script>
<style scoped>
/* 自定义高亮代码样式 */
pre {
  font-size: 18px; /* 调整字体大小 */
  line-height: 0.8; /* 调整行高 */
}

code {
  font-size: inherit; /* 使 code 的字体大小与 pre 相同 */
}
</style>